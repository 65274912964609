
import { defineComponent } from 'vue'
import { login } from '@/stuff/api';

export default defineComponent({
    data() {
        var pin = localStorage.getItem("pin");

        return {
            error: undefined as string | undefined,
            code: localStorage.getItem("code") ?? "",
            remember: !!pin,
            pin: pin ? parseInt(pin) : undefined as number | undefined
        }
    },
    methods: {
        async login() {
            if(!this.login || !this.pin)
                return;

            try {
                await login(this.code, this.pin, this.remember);
                this.$router.push('/');
            }
            catch(error) {
                this.error = error.message;
            }
        }
    }
})
