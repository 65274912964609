<template>
    <main style="width: 100%; max-width: 330px; padding 15px; margin: auto;">
        <div>
            <img src="../assets/volgnummers.png" class="w-100 mb-3"/>
            <div class="card card-primary card-outline">
                <div class="card-header">
                    <h5 class="card-title">{{ counter?.name ?? "" }}</h5>
                    <div class="card-tools">
                        <button type="button" class="btn btn-tool" @click="logout">
                            Uitloggen
                        </button>
                    </div>
                </div>
                <div class="card-body">
                    <div class="d-flex flex-column align-items-center">
                        <div>
                            Huidige oproep volgnummer:
                        </div>
                        <div class="input-group has-validation input-group-lg mb-3">
                            <input ref="input" :disabled="!editMode" type="number" class="form-control text-center" :class="{'is-invalid':editError}" v-model.number="value" v-on:keyup.enter="exitEditMode()">
                            <div class="invalid-feedback">
                                {{editError}}
                            </div>
                        </div>

                        <div>
                            <button type="button" :disabled="buzy" class="btn btn-primary btn-lg mb-3" v-if="!editMode" @click="increment()">Volgende</button>
                            <button type="button" :disabled="buzy" class="btn btn-primary btn-lg mb-3" v-if="editMode" @click="exitEditMode()">Opslaan</button>
                        </div>
                        <div>
                            <button type="button" :disabled="buzy" class="btn btn-default mb-1"  v-if="!editMode" @click="decrement()">Vorige</button>
                        </div>
                        <div>
                            <button type="button" :disabled="buzy" class="btn btn-default" v-if="!editMode" @click="enterEditMode()">Bewerk</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </main>
</template>

<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>

<script lang="ts">
import { CounterResponseData, getCurrentCounter, login, TokenResponseData } from '@/stuff/api';
import { createVolgnummersysteemHub } from '@/stuff/hub'
import { DateTime } from 'luxon';
import { HubConnection } from '@aspnet/signalr';
import { defineComponent } from 'vue'

export default defineComponent({
    data() {
        return {
            hub: undefined as HubConnection | undefined,
            counter: undefined as CounterResponseData | undefined,
            value: 0,
            editMode: false,
            buzy: false,
            editError: undefined as string | undefined
        }
    },
    methods: {
        enterEditMode() {
            this.editMode = true;
            this.$nextTick(() => (this.$refs.input as HTMLInputElement).focus());
        },
        async exitEditMode() {
            this.buzy = true;
            try{
                if(this.counter && this.value < this.counter?.min) {
                    this.editError = `Mag niet kleiner zijn dan ${this.counter?.min}`
                    return;
                }
                if(this.counter && this.value > this.counter?.max) {
                    this.editError = `Mag niet groter zijn dan ${this.counter?.max}`
                    return;
                }

                await this.hub?.invoke("Set", this.value);
                this.editError = undefined;
                this.editMode = false;
            }
            finally {
                this.buzy = false;
            }
        },
        async increment() {
            this.buzy = true;
            try{
                await this.hub?.invoke("Increment");
            }
            finally {
                this.buzy = false;
            }
        },
        async decrement() {
            this.buzy = true;
            try{
                await this.hub?.invoke("Decrement");
            }
            finally {
                this.buzy = false;
            }
        },
        async logout() {
            localStorage.removeItem('token');
            localStorage.removeItem('pin');
            this.$router.push('/login');
        },
        tryGetCurrentToken() {
            var token = localStorage.getItem('token');
            if(!token) {
                return false;
            }

            var tokenObj = JSON.parse(token) as TokenResponseData;
            if(!tokenObj) {
                return false;
            }

            var expiresAfter = DateTime.fromISO(tokenObj.expiresAfter);
            if(!expiresAfter.isValid || expiresAfter < DateTime.now()) {
                return false;
            }

            return tokenObj.token;
        },
        async tryGetOrRefreshToken() {
            let currentToken = this.tryGetCurrentToken();
            if(currentToken) {
                return currentToken;
            }

            var pin = localStorage.getItem("pin");
            var code = localStorage.getItem("code");
            if(!pin) {
                return false;
            }

            if(!code) {
                return false;
            }

            await login(code, parseInt(pin), true);

            currentToken = this.tryGetCurrentToken();
            if(!currentToken) {
                return false;
            }

            return currentToken;
        },
        navigateToLogin() {
            this.$router.push('/login');
        },
        async cleanupHub() {
            if(this.hub) {
                const tmp = this.hub;
                this.hub = undefined;
                try {
                    await tmp.stop();
                }
                catch {
                    //ignore
                }
            }
        },
        async refreshHub() {
            await this.cleanupHub();

            var token = await this.tryGetOrRefreshToken();
            if(!token) {
                this.navigateToLogin();
                return;
            }

            this.counter = await getCurrentCounter(token);
            if(!this.counter) {
                this.navigateToLogin();
                return;
            }

            this.value = this.counter.value;

            try{
                this.hub = await createVolgnummersysteemHub(token);
                await this.hub.start();

                this.hub.onclose((err) => {
                    return this.refreshHub();
                })
                
                this.hub.on(
                    "CounterUpdated",
                    (id: number, newValue:number) => {
                        if(this.counter?.id == id) {
                            this.value = newValue;
                        }
                    });
                await this.hub.invoke("RegisterForCounterUpdates", this.counter.id);
            }
            catch {
                this.navigateToLogin();
            }
        },
        
    },
    async mounted() {
        await this.refreshHub();
    },
    async unmounted() {
        await this.cleanupHub();
    }
})
</script>
