import { createApp } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'
import RouterView from '../components/RouterView.vue'
import Login from '../components/Login.vue'
import Manage from '../components/Manage.vue'

import '../assets/base.css'

const routes = [
    { path: '/', component: Manage },
    { path: '/login', component: Login },
]

export const router = createRouter({
    history: createWebHashHistory(),
    routes, 
})

const app = createApp(RouterView)
app.use(router)
app.mount('#app')
