<template>
    <main style="width: 100%; max-width: 330px; padding 15px; margin: auto;">
        <img src="../assets/volgnummers.png" class="w-100 mb-3"/>
        <div class="card card-primary card-outline">
            <div class="card-header">
                <h5 class="card-title">Login</h5>
            </div>
            <div class="card-body">
                <div class="form-floating mb-3">
                    <input v-model="code" type="text" class="form-control" id="floatingCode" placeholder="ABCDE">
                    <label for="floatingCode">Balie code</label>
                </div>
                <div class="form-floating mb-3">
                    <input v-model="pin" type="number" class="form-control" id="floatingPin" placeholder="0000">
                    <label for="floatingPin">PIN</label>
                </div>
                <div class="form-check text-start ms-1 mb-3">
                    <input type="checkbox" class="form-check-input" v-model="remember" id="formRemember">
                    <label class="form-check-label" for="formRemember">Onthoud mij</label>
                </div>
                <div v-if="error" class="alert alert-warning">
                    {{ error }}
                </div>
                <button class="w-100 btn btn-lg btn-primary" @click="login">Verder</button>
            </div>
        </div>
    </main>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { login } from '@/stuff/api';

export default defineComponent({
    data() {
        var pin = localStorage.getItem("pin");

        return {
            error: undefined as string | undefined,
            code: localStorage.getItem("code") ?? "",
            remember: !!pin,
            pin: pin ? parseInt(pin) : undefined as number | undefined
        }
    },
    methods: {
        async login() {
            if(!this.login || !this.pin)
                return;

            try {
                await login(this.code, this.pin, this.remember);
                this.$router.push('/');
            }
            catch(error) {
                this.error = error.message;
            }
        }
    }
})
</script>
