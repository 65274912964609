import { HubConnection, HubConnectionBuilder, LogLevel } from "@aspnet/signalr";
import { DateTime } from "luxon";
import { fetchConfig } from "./Config";

export type TokenResponseData = {
    token: string;
    expiresAfter: string;
}

export type CounterResponseData = {
    id: number,
    name: string,
    value: number,
    min: number,
    max: number
}

export async function login(code:string, pin:number, remember:boolean)
{
    const config = await fetchConfig();
    const data = {
        code: code,
        pin: pin
    }

    const response = await fetch(config.tokenUrl, { 
        method: 'POST', 
        headers: { 'Content-Type': 'application/json' }, 
        body: JSON.stringify(data)
    });

    if(response.ok) {
        const responseData = await response.json() as TokenResponseData;
        localStorage.setItem('code', code);
        if(remember) {
            localStorage.setItem('pin', `${pin}`);
        }
        else {
            localStorage.removeItem('pin');
        }
        responseData.expiresAfter = DateTime.fromISO(responseData.expiresAfter).plus( {minutes: -5} ).toISO();
        localStorage.setItem('token', JSON.stringify(responseData));
    }
    else if(response.status == 404) {
        throw new Error("Balie met deze code en pin is niet gevonden");
    }
    else {
        throw new Error("Onbekende fout");
    }
}

export async function getCurrentCounter(token: string)
{
    const config = await fetchConfig();

    const response = await fetch(config.counterUrl, { 
        headers: { 
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token
        }, 
    });

    if(!response.ok) {
        return undefined;
    }

    return await response.json() as CounterResponseData;
}
