type Config = 
{
    hubUrl: string,
    tokenUrl: string,
    counterUrl: string,
    soundUrl: string
}

let config : Config;

export async function fetchConfig() : Promise<Config>
{
    if(config) {
        return config;
    }

    const configUrl = new URL(location.href).searchParams.get('config') ?? "config.json";

    const response = await fetch(configUrl);

    if(response.ok == false) {
        throw new Error("Could not fetch config");
    }

    config = await response.json();

    return config;
}