import { HttpTransportType, HubConnection, HubConnectionBuilder, LogLevel } from "@aspnet/signalr";
import { fetchConfig } from "./Config";

export async function createVolgnummersysteemHub(token?:string) : Promise<HubConnection>
{
    const config = await fetchConfig();

    return new HubConnectionBuilder()
        .withUrl(config.hubUrl, { accessTokenFactory: token ? () => token : undefined, transport: HttpTransportType.LongPolling, skipNegotiation: false })
        .configureLogging(LogLevel.Debug)
        .build();
}